export const CONST = {
  // BACKEND_URL: 'http://localhost:5000/api/v1',
  // BACKEND_HOST: 'http://localhost:5000/',

  
  BACKEND_URL: 'https://talentplusstrengths.com/api/v1',
  BACKEND_HOST: 'https://www.talentplusstrengths.com/',

  SURVEY_URL: "https://talentplusstrengths.com:8080//#/v1/",
  SURVEY_LOGIN: "https://talentplusstrengths.com:8080//#/login/",

  SURVEY_ASSESSMENT: "https://talentplusstrengths.com//#/assessment/surveylogin",
  SURVEY_ASSESSMENT_USER: "https://talentplusstrengths.com//#/assessment/sign-in",

  MERCHANT_ID: "2484086", //Int or String

  CRYPTO_KEY: "secretkey1345100237401", //Int or String

  MANAGER_ID_TYPE: "Int", //Int or String

  APP_NAME: 'PLUS HR',
  FOOTER_TEXT: '©2023 Happyplus. All rights reserved.',

};
