import React from "react";
import Summary from "./Summary/Summary";
import BackSummary from "./Summary/BackSummary";
import AssessmentReport from "../../../Surveylink/Component/AssessmentReport/AssessmentReport";
import AssessmentSummary from "../../../Surveylink/Component/AssessmentSummary/AssessmentSummary";
import AssessmentManagerReport from "../../../Surveylink/Component/AssessmentManagerReport/AssessmentManagerReport";
import AssessmentHiringReport from "../../../Surveylink/Component/AssessmentHiringReport/AssessmentHiringReport";
import AssessmentReportSchool from "../../../Surveylink/Component/AssessmentReportSchool/AssessmentReportSchool";
import AssessmentReportCareer from "../../../Surveylink/Component/AssessmentReportCareer/AssessmentReportCareer";
import SpeedMeter from "./SpeedMeter"
import SummaryCareer from "./Summary/SummaryCareer";

export default function Component(props) {
  if (!props) {
    // Handle the case where props is undefined
    return null; // or render an appropriate component or message
  }
  const {
    getAssessmentReportByEmpIDData,
    getAssessmenrEmployeeDetailsData,
    createdAt,
    EditButtonFn,
    EditDetailsFn,
    loading,
    closeReportummaryModal,
    backSummary,
    showMainSummaryModal,
    handleShowMainSummaryModal,
    handleShowMainSummaryModalBack,
    contactExpertSection,
    handleExpertContactModal,
    topFiveTalent,
    handleExpertContactModalBack,
    crrDetail,
    crrCodeUserDetails,
    handleInputExpertForm,
    bookExpert,
    userAssessmentExpertOrderPlaceData,
    handleTestPayment,

    isSchool,

    showMainMenu,
    handleMainMenu,
    handleDashboard,
    showDashboard,
    showReportModal,
    showSummaryReport,
    showManagerReportModal,
    showHiringReportModal,
    showSchoolReportModal,
    crrEmpDetails,

    closeReportModal,
    closeSummaryReportModal,
    closeManagerReportModal,
    closeHiringReportModal,
    code,
    getCareerFilamentScoreData, getCareerSurveyListData, getPersonalityIndexData,
    getWorkValueDimensionScoreData, getInterestDimensionScoreData,
    getAptitudeTestScoreData
  } = props;

  // console.log("code-----C----->", code);
  // console.log("showMainMenu-----C----->", showMainMenu);

  console.log("11111111111111111111111111111getAptitudeTestScoreData-->", getAptitudeTestScoreData);
  
  function getModifiedContent(text) {
    let text2 = "";
    if (text) {
      text2 = text
        .replace(/<\/?li[^>]*>/g, "@")
        .replace(/<\/?ul[^>]*>/g, "")
        .replace(/<\/?span[^>]*>/g, "")
        .replace(/<\/?p[^>]*>/g, "")
        .replace("&nbsp;", "")
        .replace(/<\/?br[^>]*>/g, "")
        .replace(/<\/?b[^>]*>/g, "");
    }
    return text2 ? text2 : text;
  }

  function hexToRgbA(hex, opacity) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return (
        "rgba(" +
        [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
        "," +
        opacity +
        ")"
      );
    }
    throw hex;
  }

  // console.log("getAssessmentReportByEmpIDData--->", getAssessmentReportByEmpIDData);

  function getWeightage(rank) {
    let value = 0;
    value = 22 - rank;
    return value
  }

  let getAssessmentDimensionsData = [];

  if (getAssessmentReportByEmpIDData && getAssessmentReportByEmpIDData.length > 0) {
    for (var i = 0; i < getAssessmentReportByEmpIDData.length; i++) {
      let detail = getAssessmentReportByEmpIDData[i];

      getAssessmentDimensionsData.push({
        "bucket": detail.bucket,
        "colorCode": detail.colorCode,
        "count": detail.count,
        "dimension": detail.dimension,
        "finalSum": Number(detail.count) * Number(detail.rankSum),
        "rankSum": getWeightage(i + 1)
      });

    }

    getAssessmentDimensionsData.sort((a, b) => a.rankSum - b.rankSum)

  }

  function checkForGeneralship(bucket, dimension, isSchool) {

    // console.log('isSchoolisSchoolisSchoolisSchoolisSchoolisSchool', isSchool);

    let text = "";
    if (bucket && dimension) {
      if (isSchool) {
        text = "school_";
      } else {
        if (dimension.toLowerCase() === "generalship" && bucket.toLowerCase() === "thinking talents") {
          text = "old";
        } else if (dimension.toLowerCase() === "enhancer" && bucket.toLowerCase() === "relating talents") {
          text = "old";
        } else if (dimension.toLowerCase() === "coach" && bucket.toLowerCase() === "leading talents") {
          text = "old";
        }
      }

    }
    return text
  }

  function getTopBottomIndex(list, type) {
    let range1 = 0;
    let range2 = 0;
    if (list && list.length > 0) {
      if (type === "top") {
        range1 = 5;
        range2 = 5;
      } else if (type === "mid") {
        range1 = 6;
        range2 = list.length - 5;
      } else if (type === "bottom") {
        range1 = list.length - 4
        range2 = list.length - 4
      }
    }
    return {
      "range1": range1,
      "range2": range2
    }
  }

  const personalityData = [
    { "name": "Learning Beliefs" },
    { "name": "Interpersonal Beliefs" },
    { "name": "Emotional Beliefs" },
    { "name": "Attitudinal Beliefs" },
    { "name": "Conscientious Beliefs" },
    { "name": "Learning Beliefs" },
  ]

  return (
    <>

      {/* /assessment/assessment-code */}
      <div className="fixed top-0 bottom-0 left-0 right-0 z-40 w-full h-screen bg-black bg-opacity-60" >
        <div className="flex items-start justify-center h-screen p-4 overflow-hidden">

          <div className="mx-auto overflow-y-auto  bg-[#FAFAFA] rounded-md 2xl:w-5/6 xl:w-5/6 lg:w-5/6 customscroll5 h-[calc(100vh-2rem)]"  >


            <>
              {(getAssessmentDimensionsData && getAssessmentDimensionsData.length > 0) || (code === "TeenCareerGuidance") ?
                <>
                  <section className="w-full ">

                    {showMainSummaryModal ?
                      <>
                        <div className="w-full mx-auto space-y-6">
                          <div className="sticky top-0 z-40 flex items-center justify-between px-4 bg-white lg:space-y-0 md:px-10"> 

                            <div className="flex space-x-6 text-sm md:justify-start md:space-x-8 whitespace-nowrap lg:text-base md:px-0 px-6"> 

                              <button onClick={() => handleMainMenu("Summary")} className={showMainMenu === "Summary" ? "flex md:flex-row flex-col gap-2 items-center   md:py-4 py-2 border-b-[3px] border-[#2196F3] text-[#212121" : "flex md:flex-row flex-col gap-2 items-center  md:py-4 py-2 border-b-[3px] border-transparent text-[#212121] opacity-90"}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 40 40" fill="none">
                                  <g clipPath="url(#clip0_4081_272)">
                                    <path d="M35.556 6.66667H4.44488C3.85551 6.66667 3.29028 6.9008 2.87353 7.31755C2.45678 7.73429 2.22266 8.29952 2.22266 8.88889V31.1111C2.22266 31.7005 2.45678 32.2657 2.87353 32.6825C3.29028 33.0992 3.85551 33.3333 4.44488 33.3333H35.556C36.1454 33.3333 36.7106 33.0992 37.1273 32.6825C37.5441 32.2657 37.7782 31.7005 37.7782 31.1111V8.88889C37.7782 8.29952 37.5441 7.73429 37.1273 7.31755C36.7106 6.9008 36.1454 6.66667 35.556 6.66667ZM35.556 31.1111H4.44488V8.88889H35.556V31.1111Z" fill="#212121" />
                                    <path d="M9.99978 15.5556H29.9998C30.2945 15.5556 30.5771 15.4385 30.7855 15.2301C30.9938 15.0217 31.1109 14.7391 31.1109 14.4444C31.1109 14.1498 30.9938 13.8671 30.7855 13.6588C30.5771 13.4504 30.2945 13.3333 29.9998 13.3333H9.99978C9.7051 13.3333 9.42248 13.4504 9.21411 13.6588C9.00574 13.8671 8.88867 14.1498 8.88867 14.4444C8.88867 14.7391 9.00574 15.0217 9.21411 15.2301C9.42248 15.4385 9.7051 15.5556 9.99978 15.5556Z" fill="#212121" />
                                    <path d="M9.99978 20H29.9998C30.2945 20 30.5771 19.8829 30.7855 19.6746C30.9938 19.4662 31.1109 19.1836 31.1109 18.8889C31.1109 18.5942 30.9938 18.3116 30.7855 18.1032C30.5771 17.8948 30.2945 17.7778 29.9998 17.7778H9.99978C9.7051 17.7778 9.42248 17.8948 9.21411 18.1032C9.00574 18.3116 8.88867 18.5942 8.88867 18.8889C8.88867 19.1836 9.00574 19.4662 9.21411 19.6746C9.42248 19.8829 9.7051 20 9.99978 20Z" fill="#212121" />
                                    <path d="M9.99978 24.4445H21.1109C21.4056 24.4445 21.6882 24.3274 21.8966 24.119C22.1049 23.9106 22.222 23.628 22.222 23.3333C22.222 23.0387 22.1049 22.756 21.8966 22.5477C21.6882 22.3393 21.4056 22.2222 21.1109 22.2222H9.99978C9.7051 22.2222 9.42248 22.3393 9.21411 22.5477C9.00574 22.756 8.88867 23.0387 8.88867 23.3333C8.88867 23.628 9.00574 23.9106 9.21411 24.119C9.42248 24.3274 9.7051 24.4445 9.99978 24.4445Z" fill="#212121" />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_4081_272">
                                      <rect width="40" height="40" fill="white" />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <span>Summary</span>
                              </button>

                              <button onClick={() => handleMainMenu("Report")} className={showMainMenu === "Report" ? "flex md:flex-row flex-col gap-2 items-center md:py-4 py-2 border-b-[3px] border-[#2196F3] text-[#212121" : "flex md:flex-row flex-col gap-2 items-center md:py-4 py-2 border-b-[3px] border-transparent text-[#212121] opacity-90"}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 40 40" fill="none">
                                  <path d="M5 35V31.6667C5 29.8986 5.70238 28.2029 6.95262 26.9526C8.20286 25.7024 9.89856 25 11.6667 25H18.3333C20.1014 25 21.7971 25.7024 23.0474 26.9526C24.2976 28.2029 25 29.8986 25 31.6667V35M26.6667 5.21667C28.1007 5.58384 29.3717 6.41784 30.2794 7.58718C31.1871 8.75653 31.6797 10.1947 31.6797 11.675C31.6797 13.1553 31.1871 14.5935 30.2794 15.7628C29.3717 16.9322 28.1007 17.7662 26.6667 18.1333M35 35V31.6667C34.9915 30.1953 34.4965 28.768 33.5921 27.6073C32.6877 26.4467 31.4247 25.6178 30 25.25M8.33333 11.6667C8.33333 13.4348 9.03571 15.1305 10.286 16.3807C11.5362 17.631 13.2319 18.3333 15 18.3333C16.7681 18.3333 18.4638 17.631 19.714 16.3807C20.9643 15.1305 21.6667 13.4348 21.6667 11.6667C21.6667 9.89856 20.9643 8.20286 19.714 6.95262C18.4638 5.70238 16.7681 5 15 5C13.2319 5 11.5362 5.70238 10.286 6.95262C9.03571 8.20286 8.33333 9.89856 8.33333 11.6667Z" stroke="#212121" stroke-opacity="0.7" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>
                                <span>Report</span>
                              </button>

                            </div>


                            <div className="flex flex-col justify-end gap-4 md:flex-row">
                              <span onClick={() => closeReportummaryModal(false)} className="p-2 text-gray-500 duration-150 bg-gray-100 rounded-full cursor-pointer material-symbols-outlined hover:bg-gray-200 trasition" style={{ fontSize: "28px" }}>close</span>
                            </div>
                          </div>

                          <section className="w-full px-4 mx-auto space-y-6 xl:w-[95%] xl:px-0">
                            {showMainMenu === "Report" ?
                              <>

                                {code === "TalentPlusStrengths21" ? (
                                  <>
                                    <AssessmentReport
                                      closeReportModal={closeReportModal}
                                      getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                                      getAssessmenrEmployeeDetailsData={crrEmpDetails}
                                      createdAt={createdAt}
                                      EditButtonFn={EditButtonFn}
                                      EditDetailsFn={EditDetailsFn}
                                      handleDownloadStatus={() => { }}
                                      loading={loading}
                                      isUser={false}
                                    />
                                  </>
                                ) : null}

                                {code === "Top5TalentplusStrengths" ? (
                                  <>
                                    <AssessmentSummary
                                      closeReportModal={closeSummaryReportModal}
                                      getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                                      getAssessmenrEmployeeDetailsData={crrEmpDetails}
                                      createdAt={createdAt}
                                      EditButtonFn={EditButtonFn}
                                      EditDetailsFn={EditDetailsFn}
                                      handleDownloadStatus={() => { }}
                                      loading={loading}
                                      isUser={false}
                                    />
                                  </>
                                ) : null}

                                {code === "TalentplusManagerStrengths" ? (
                                  <>
                                    <AssessmentManagerReport
                                      closeReportModal={closeManagerReportModal}
                                      getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                                      getAssessmenrEmployeeDetailsData={crrEmpDetails}
                                      createdAt={createdAt}
                                      EditButtonFn={EditButtonFn}
                                      EditDetailsFn={EditDetailsFn}
                                      handleDownloadStatus={() => { }}
                                      loading={loading}
                                      isUser={false}
                                    />
                                  </>
                                ) : null}

                                {code === "TalentPlusHiringStrengths" ? (
                                  <AssessmentHiringReport
                                    closeReportModal={closeHiringReportModal}
                                    getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                                    getAssessmenrEmployeeDetailsData={crrEmpDetails}
                                    createdAt={createdAt}
                                    EditButtonFn={EditButtonFn}
                                    EditDetailsFn={EditDetailsFn}
                                    handleDownloadStatus={() => { }}
                                    loading={loading}
                                    isUser={false}
                                  />
                                ) : null}

                                {code === "Top5TeenTalentplusStrengths" ? (
                                  <AssessmentReportSchool
                                    closeReportModal={closeHiringReportModal}
                                    getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                                    getAssessmenrEmployeeDetailsData={crrEmpDetails}
                                    createdAt={createdAt}
                                    EditButtonFn={EditButtonFn}
                                    EditDetailsFn={EditDetailsFn}
                                    handleDownloadStatus={() => { }}
                                    loading={loading}
                                    isUser={false}
                                  />
                                ) : null}

                                {code === "TeenCareerGuidance" ? (
                                  <AssessmentReportCareer
                                    closeReportModal={closeHiringReportModal}
                                    getAssessmenrEmployeeDetailsData={crrEmpDetails}
                                    createdAt={createdAt}
                                    EditButtonFn={EditButtonFn}
                                    EditDetailsFn={EditDetailsFn}
                                    handleDownloadStatus={() => { }}
                                    loading={loading}
                                    isUser={false}

                                    getCareerFilamentScoreData={getCareerFilamentScoreData}
                                    getCareerSurveyListData={getCareerSurveyListData}
                                    getPersonalityIndexData={getPersonalityIndexData}
                                    getAptitudeTestScoreData={getAptitudeTestScoreData}
                                    getWorkValueDimensionScoreData={getWorkValueDimensionScoreData}
                                    getInterestDimensionScoreData={getInterestDimensionScoreData}

                                  />
                                ) : null}

                              </> : null}

                            {showMainMenu === "Summary" ?
                              <>
                                {code === "TeenCareerGuidance" ?
                                  (

                                    <SummaryCareer
                                      getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                                      getAssessmenrEmployeeDetailsData={crrEmpDetails}
                                      createdAt={createdAt}
                                      EditButtonFn={EditButtonFn}
                                      EditDetailsFn={EditDetailsFn}
                                      handleDownloadStatus={() => { }}
                                      loading={loading}
                                      isUser={false}

                                      getAptitudeTestScoreData={getAptitudeTestScoreData}
                                      getCareerFilamentScoreData={getCareerFilamentScoreData}
                                      getCareerSurveyListData={getCareerSurveyListData}
                                      getPersonalityIndexData={getPersonalityIndexData}
                                      getWorkValueDimensionScoreData={getWorkValueDimensionScoreData}
                                      getInterestDimensionScoreData={getInterestDimensionScoreData}


                                    />
                                  ) :
                                  (
                                    <>

                                      <div className="p-6 space-y-2 text-base font-normal text-left bg-white border rounded-lg md:space-y-6">
                                        <h1 className=" text-2xl font-semibold  text-[#212121]">
                                          {
                                            isSchool ? EditButtonFn('TalentAssessmentSummary', "Teen TalentPlusStrengths  Assessment Summary") : EditButtonFn('TalentAssessmentSummary', "TalentPlusStrengths  Assessment Summary")
                                          }
                                        </h1>
                                        <div className="flex items-start lg:space-x-4">
                                          <div className="lg:flex hidden items-center justify-center w-16 h-16 flex-shrink-0 bg-[#FFC107]/10 rounded-md">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                              <path d="M20 3.75C16.7861 3.75 13.6443 4.70305 10.972 6.48862C8.29969 8.27419 6.21689 10.8121 4.98696 13.7814C3.75704 16.7507 3.43524 20.018 4.06225 23.1702C4.68926 26.3224 6.23692 29.2179 8.50952 31.4905C10.7821 33.7631 13.6776 35.3107 16.8298 35.9378C19.982 36.5648 23.2493 36.243 26.2186 35.013C29.1879 33.7831 31.7258 31.7003 33.5114 29.028C35.297 26.3557 36.25 23.2139 36.25 20C36.2455 15.6916 34.5319 11.561 31.4855 8.51454C28.439 5.46806 24.3084 3.75455 20 3.75ZM20 33.75C17.2805 33.75 14.6221 32.9436 12.3609 31.4327C10.0997 29.9218 8.33737 27.7744 7.29666 25.2619C6.25596 22.7494 5.98366 19.9847 6.51421 17.3175C7.04476 14.6503 8.35432 12.2003 10.2773 10.2773C12.2003 8.35431 14.6503 7.04475 17.3175 6.5142C19.9848 5.98366 22.7494 6.25595 25.2619 7.29666C27.7744 8.33736 29.9218 10.0997 31.4327 12.3609C32.9436 14.6221 33.75 17.2805 33.75 20C33.7459 23.6455 32.2959 27.1404 29.7182 29.7182C27.1404 32.2959 23.6455 33.7459 20 33.75ZM22.5 27.5C22.5 27.8315 22.3683 28.1495 22.1339 28.3839C21.8995 28.6183 21.5815 28.75 21.25 28.75C20.587 28.75 19.9511 28.4866 19.4822 28.0178C19.0134 27.5489 18.75 26.913 18.75 26.25V20C18.4185 20 18.1005 19.8683 17.8661 19.6339C17.6317 19.3995 17.5 19.0815 17.5 18.75C17.5 18.4185 17.6317 18.1005 17.8661 17.8661C18.1005 17.6317 18.4185 17.5 18.75 17.5C19.413 17.5 20.0489 17.7634 20.5178 18.2322C20.9866 18.7011 21.25 19.337 21.25 20V26.25C21.5815 26.25 21.8995 26.3817 22.1339 26.6161C22.3683 26.8505 22.5 27.1685 22.5 27.5ZM17.5 13.125C17.5 12.7542 17.61 12.3916 17.816 12.0833C18.022 11.775 18.3149 11.5346 18.6575 11.3927C19.0001 11.2508 19.3771 11.2137 19.7408 11.286C20.1045 11.3584 20.4386 11.537 20.7008 11.7992C20.9631 12.0614 21.1416 12.3955 21.214 12.7592C21.2863 13.1229 21.2492 13.4999 21.1073 13.8425C20.9654 14.1851 20.725 14.478 20.4167 14.684C20.1084 14.89 19.7458 15 19.375 15C18.8777 15 18.4008 14.8025 18.0492 14.4508C17.6976 14.0992 17.5 13.6223 17.5 13.125Z" fill="#FFC107" />
                                            </svg>
                                          </div>
                                          <p className="text-base  text-[#212121] md:text-lg " >
                                            {EditButtonFn('TalentAssessmentDesc', `Your talents are the essence of who you are, shaping how you think, feel, and act. Understanding and leveraging these talents are key to success. They enable you to find your flow, excel at work, and stand out authentically. Embracing your uniqueness leads to fulfillment and happiness, allowing you to be your true self.`)}
                                          </p>
                                        </div>
                                      </div>

                                      <div className="space-y-6 lg:flex lg:space-x-6 lg:space-y-0">
                                        <Summary
                                          getAssessmentDimensionsData={getAssessmentDimensionsData}
                                          EditButtonFn={EditButtonFn}
                                          loading={loading}
                                          isSchool={isSchool}
                                        />
                                      </div>

                                      <div className="space-y-6 lg:flex lg:space-x-6 lg:space-y-0 ">
                                        <div className="lg:w-[30%] w-full">
                                          <div className="w-full p-4 space-y-6 bg-white border rounded-lg md:p-8">
                                            <div className="pb-4 border-b ">
                                              <h1 className="text-xl font-bold text-gray-800 md:font-semibold md:text-2xl">
                                                {
                                                  isSchool ? EditButtonFn('ViewReport', "Domains Key of TEEN TALENTPLUSSTRENGTHS") : EditButtonFn('ViewReport', "Domains Key of TALENTPLUSSTRENGTHS")
                                                }
                                              </h1>
                                            </div>


                                            {isSchool ?
                                              <div className="w-full space-y-4">
                                                <div className="flex items-start w-full space-x-2 space-y-2">
                                                  <div className="space-y-2 text-md">
                                                    <div className="flex items-center mr-2 text-base font-bold">
                                                      <p
                                                        className="w-4 h-4 mb-1 mr-2 text-lg bg-red-500"
                                                        style={{ background: "#673AB7" }}
                                                      />
                                                      {EditButtonFn("LEARNINGTALENTS", "LEARNING TALENTS")}
                                                    </div>
                                                    <p className="text-base text-slate-600">
                                                      {EditButtonFn(
                                                        "LEARNINGDESC",
                                                        "Learning talents encompass intellectual abilities, emphasizing deep thinking, exploring diverse themes, and strategic planning for the future. Individuals possessing these talents demonstrate a keen focus on intellectual pursuits, navigating complexities, and analyzing information to make informed decisions, using their ability to deliberate on ideas as a strategic pathway for continuous learning and growth."
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                                <br />
                                                <div className="flex items-start w-full space-x-2 space-y-2">
                                                  <div className="space-y-2">
                                                    <div className="flex items-center mr-2 text-base font-bold">
                                                      <p
                                                        className="w-4 h-4 mb-1 mr-2 text-lg bg-red-500"
                                                        style={{ background: "#ffc107" }}
                                                      />
                                                      {EditButtonFn('THINKINGTALENTS', "THINKING TALENTS")}
                                                    </div>
                                                    <p className="text-base text-slate-600">
                                                      {EditButtonFn(
                                                        "THINKINGDESC",
                                                        "These talents shape your thinking and perception of the world, allowing you to deliberate on ideas, possibilities, and future actions. They encompass intellectual abilities, which drive human progress. By contemplating these themes, we explore our past, envision the future, and plan in the present. Thinking talents serve as the strategic pathway to our future."
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                                <br />
                                                <div className="flex items-start w-full space-x-2 space-y-2">
                                                  <div className="space-y-2">
                                                    <span className={"w-5 h-5 rounded-full "} />
                                                    <div className="flex items-center mr-2 text-base font-bold">
                                                      <p
                                                        className="w-4 h-4 mr-2 bg-red-500"
                                                        style={{ background: "#4caf50" }}
                                                      />
                                                      {EditButtonFn('RELATINGTALENTS', "RELATING TALENTS")}
                                                    </div>
                                                    <p className="text-base text-slate-600">
                                                      {EditButtonFn(
                                                        "RELATINGDESC",
                                                        "As social creatures, humans thrive through connections with others. Sharing successes, hardships, and experiences with fellow beings is essential for our well-being and progress. These bonds elevate us, enabling collective achievement of goals, overcoming challenges, and shaping history together."
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                                <br />
                                                <div className="flex items-start w-full space-x-2 space-y-2">
                                                  <div className="space-y-2">
                                                    <span
                                                      style={{ background: "#2196f3" }}
                                                      className={"w-5 h-5 rounded-full "}
                                                    />
                                                    <div className="flex items-center mr-2 text-base font-bold">
                                                      <p
                                                        className="w-4 h-4 mr-2 bg-red-500"
                                                        style={{ background: "#2196f3" }}
                                                      />
                                                      {EditButtonFn('ACTINGTALENTS', "ACTING TALENTS")}
                                                    </div>
                                                    <p className="text-base text-slate-600">
                                                      {EditButtonFn(
                                                        "ACTINGDESC",
                                                        "Action talents drive progress by turning ideas into reality. While ideas are important, true advancement occurs through action. Acting talents empower us to translate thoughts into tangible outcomes, driving visible progress and consistent achievement of tasks and goals."
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>

                                                <div className="flex items-start w-full space-x-2 space-y-2">
                                                  <div className="space-y-2">
                                                    <span
                                                      style={{ background: "#FF9800" }}
                                                      className={"w-5 h-5 rounded-full "}
                                                    />
                                                    <div className="flex items-center mr-2 text-base font-bold">
                                                      <p
                                                        className="w-4 h-4 mr-2 bg-[#FF9800]"
                                                        style={{ background: "#FF9800" }}
                                                      />
                                                      {EditButtonFn("EMOTIONSTALENTS", "EMOTIONS TALENTS")}
                                                    </div>
                                                    <p className="text-base text-slate-600">
                                                      {EditButtonFn(
                                                        "EMOTIONSDESC",
                                                        "Emotion talents, encapsulating the Excitable, Circumspect, and Non-Reactive themes, provide individuals with versatile approaches to engaging and expressing emotions. Recognizing and utilizing these emotional themes can improve interpersonal relationships, facilitating effective communication and collaboration. Striking a balance among these talents enables individuals to navigate a broad spectrum of emotional expressions, adapting to diverse situations with emotional intelligence and resilience."
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>

                                                <div className="flex items-start w-full space-x-2 space-y-2">
                                                  <div className="space-y-2">
                                                    <span
                                                      style={{ background: "#FF9800" }}
                                                      className={"w-5 h-5 rounded-full "}
                                                    />
                                                    <div className="flex items-center mr-2 text-base font-bold">
                                                      <p
                                                        className="w-4 h-4 mr-2 bg-[#00BCD4]"
                                                        style={{ background: "#00BCD4" }}
                                                      />
                                                      {EditButtonFn("COMMUNICATINGTALENTS", "COMMUNICATING TALENTS")}
                                                    </div>
                                                    <p className="text-base text-slate-600">
                                                      {EditButtonFn(
                                                        "COMMUNICATINGDESC",
                                                        "Communicating talent involves adeptly conveying thoughts and ideas, utilizing various communication styles to connect with others effectively. This talent extends beyond linguistic skills, encompassing active listening, understanding diverse perspectives, and adapting communication strategies for successful interactions in both personal and professional settings."
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>



                                              </div>
                                              :
                                              <div className="w-full space-y-4">
                                                <div className="flex items-start w-full space-x-2 space-y-2">
                                                  <div className="space-y-2 text-md">
                                                    <div className="flex items-center mr-2 text-base font-bold">
                                                      <p
                                                        className="w-4 h-4 mb-1 mr-2 text-lg bg-red-500"
                                                        style={{ background: "#FFC107" }}
                                                      />
                                                      {EditButtonFn('THINKINGTALENTS', "THINKING TALENTS")}
                                                    </div>
                                                    <p className="text-base text-slate-600">
                                                      {EditButtonFn(
                                                        "THINKINGDESC",
                                                        "These are the talents which define your way of thinking and making a sense of the world. Using these talents, you deliberate on ideas, possibilities, and next course of action. This theme deals with intellectual abilities which is the hallmark of human progress. Thinking about themes allows us to explore our past and gaze into the future so that we can plan things in the present. Thinking talents are the strategic pathway to our future."
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                                <br />
                                                <div className="flex items-start w-full space-x-2 space-y-2">
                                                  <div className="space-y-2">
                                                    <div className="flex items-center mr-2 text-base font-bold">
                                                      <p
                                                        className="w-4 h-4 mb-1 mr-2 text-lg bg-red-500"
                                                        style={{ background: "#4CAF50" }}
                                                      />
                                                      {EditButtonFn('RELATINGTALENTS', "RELATING TALENTS")}
                                                    </div>
                                                    <p className="text-base text-slate-600">
                                                      {EditButtonFn(
                                                        "RELATINGDESC",
                                                        "Human beings are social animals. We thrive with others. We need others in life to share our successes, miseries, and our condition. The ability to connect with others is what defines the possibilities of our success in life. It is the bond between one person and another, which takes us higher and ensures we come together to achieve the goals we set for ourselves, challenges we want to overcome and write history together."
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                                <br />
                                                <div className="flex items-start w-full space-x-2 space-y-2">
                                                  <div className="space-y-2">
                                                    <span className={"w-5 h-5 rounded-full "} />
                                                    <div className="flex items-center mr-2 text-base font-bold">
                                                      <p
                                                        className="w-4 h-4 mr-2 bg-red-500"
                                                        style={{ background: "#2196F3" }}
                                                      />
                                                      {EditButtonFn('ACTINGTALENTS', "ACTING TALENTS")}
                                                    </div>
                                                    <p className="text-base text-slate-600">
                                                      {EditButtonFn(
                                                        "ACTINGDESC",
                                                        "These are action talents, which allow you to make things happen. Ideas are good but the real progress takes place when we put these ideas to action. The acting talent allows us to put our thoughts into action and see the magic happen. It is these themes which make things happen and lets everyone see the progress happening. Our acting talents ensure that we make progress and finish tasks and achieve things consistently."
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>
                                                <br />
                                                <div className="flex items-start w-full space-x-2 space-y-2">
                                                  <div className="space-y-2">
                                                    <span
                                                      style={{ background: "#673AB7" }}
                                                      className={"w-5 h-5 rounded-full "}
                                                    />
                                                    <div className="flex items-center mr-2 text-base font-bold">
                                                      <p
                                                        className="w-4 h-4 mr-2 bg-red-500"
                                                        style={{ background: "#673AB7" }}
                                                      />
                                                      {EditButtonFn('LEADINGTALENTS', "LEADING TALENTS")}
                                                    </div>
                                                    <p className="text-base text-slate-600">
                                                      {EditButtonFn(
                                                        "LEADINGDESC",
                                                        "These are the talents, which defines our leadership. Leadership is not a position but it is a mindset, through which people share their vision with others, get them aligned and motivated and ensure work takes place towards that desired future. Leaders are the people responsible for a better tomorrow, so they not only have to keep looking at the things to come but also make that dream a reality with the help of others."
                                                      )}
                                                    </p>
                                                  </div>
                                                </div>


                                              </div>
                                            }
                                          </div>
                                        </div>

                                        <div className="lg:w-[70%] w-full space-y-6">
                                          <div className="flex items-center justify-between w-full p-4 bg-white border md:p-8 rounded-xl">
                                            <div className="w-full space-y-4">
                                              {getAssessmentReportByEmpIDData &&
                                                getAssessmentReportByEmpIDData.length > 0
                                                ? getAssessmentReportByEmpIDData.map((element, index1) => (
                                                  index1 + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "top").range1 ?
                                                    <>
                                                      {index1 + 1 === 1 ? (
                                                        <div className="space-y-4 ">
                                                          <h1 className="text-xl font-bold text-gray-800 md:font-semibold md:text-2xl">
                                                            {
                                                              isSchool ? EditButtonFn('Top5TalentPlustheme', "Dominant Teen TalentPlusStrengths theme") : EditButtonFn('Top5TalentPlustheme', "Dominant TalentPlusStrengths theme")
                                                            }
                                                          </h1>
                                                          <p className="text-base">
                                                            {
                                                              isSchool ? EditButtonFn('Top5TalentPlusthemeDesc', "Your Dominant Teen TalentPlusStrengths theme: (Broadly they define who you are what makes you successful, they need tobe understood, nurtured and used regularly to experience a thriving life)") : EditButtonFn('Top5TalentPlusthemeDesc', "Your Dominant TalentPlusStrengths theme: (Broadly they define who you are what makes you successful, they need tobe understood, nurtured and used regularly to experience a thriving life)")
                                                            }
                                                          </p>
                                                        </div>
                                                      ) : null}

                                                      {index1 + 1 <= getTopBottomIndex(getAssessmentReportByEmpIDData, "top").range1 ? (
                                                        <>
                                                          <div
                                                            onClick={() => handleShowMainSummaryModal(element)}
                                                            style={{ background: hexToRgbA(element && element.colorCode ? element.colorCode : "#DDDDDD", "0.20"), }}
                                                            className={"cursor-pointer rounded-2xl 2xl:px-8 md:py-4 p-4 md:flex justify-between  items-center md:space-x-6 md:space-y-0 space-y-4 "}>
                                                            <div className="2xl:space-y-4 space-y-2 md:w-[30%]"   >

                                                              <button style={{ background: element && element.colorCode ? element.colorCode : "#DDDDDD", }}
                                                                className={"px-4 py-2 2xl:text-sm text-xs font-medium flex text-white uppercase tracking-widest rounded-full"}>
                                                                {EditDetailsFn(element, 'bucket', element.bucket)}
                                                              </button>
                                                              <h1 className="text-xl font-medium 2xl:text-2xl text-slate-900 md:px-4 ">
                                                                {((index1 + 1) > 16 ? (index1 + 1 - 16).toString() : (index1 + 1).toString()) + ". "}{EditDetailsFn(element, 'dimension', element.dimension)}
                                                              </h1>
                                                            </div>

                                                            <div className="md:w-[70%] flex justify-between space-x-6 md:items-center items-end ">
                                                              <p className="flex-1 text-sm font-normal md:text-base text-slate-600 ">
                                                                {EditDetailsFn(element, 'desc', element.details.desc)}
                                                              </p>
                                                              <div className="w-16 xl:w-28 md:w-20">
                                                                <img src={"/img/assessment/circleicons/" + (checkForGeneralship(element.bucket, element.dimension, isSchool)) + ((element.dimension.replace(' ', '_')).toLowerCase()) + ".svg"} />
                                                                {/* <img src={"/img/assessment/circleicons/" + element.dimension.toLowerCase() + ".svg"} /> */}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </>
                                                      ) : null}
                                                    </>
                                                    : null))
                                                : null}
                                            </div>
                                          </div>

                                          <div className="flex items-center justify-between w-full p-4 bg-white border cursor-default md:p-8 rounded-xl">
                                            <div className="w-full space-y-4">
                                              {getAssessmentReportByEmpIDData &&
                                                getAssessmentReportByEmpIDData.length > 0
                                                ? getAssessmentReportByEmpIDData.map((element, index1) => (
                                                  getTopBottomIndex(getAssessmentReportByEmpIDData, "bottom").range1 <= index1 + 1 ?
                                                    <>

                                                      {getTopBottomIndex(getAssessmentReportByEmpIDData, "bottom").range1 === index1 + 1 ? (
                                                        <div className="space-y-4 ">
                                                          <h1 className="text-xl font-bold text-gray-800 md:font-semibold md:text-2xl">
                                                            {
                                                              isSchool ? EditButtonFn('Bottom5TalentPlustheme', "Non Dominant Teen TalentPlusStrengths  theme") : EditButtonFn('Bottom5TalentPlustheme', "Non Dominant TalentPlusStrengths  theme")
                                                            }
                                                          </h1>
                                                          <p className="text-base">
                                                            {
                                                              isSchool ? EditButtonFn('Bottom5TalentPlusthemeDesc', "Your Non Dominant Teen TalentPlusStrengths  Themes (These are the talents, which are there but not much & you may need tolearn how to manage it to achieve your goals in life)") : EditButtonFn('Bottom5TalentPlusthemeDesc', "Your Non Dominant TalentPlusStrengths  Themes (These are the talents, which are there but not much & you may need tolearn how to manage it to achieve your goals in life)")
                                                            }
                                                          </p>
                                                        </div>
                                                      ) : null}

                                                      {getTopBottomIndex(getAssessmentReportByEmpIDData, "bottom").range1 <= index1 + 1 ? (
                                                        <>
                                                          <div
                                                            style={{
                                                              background: hexToRgbA(
                                                                element && element.colorCode
                                                                  ? element.colorCode
                                                                  : "#DDDDDD",
                                                                "0.20"
                                                              ),
                                                            }}
                                                            className={
                                                              "rounded-2xl 2xl:px-8 md:py-4 p-4 md:flex justify-between  items-center md:space-x-6 md:space-y-0 space-y-4 "
                                                            }
                                                          >
                                                            <div className="2xl:space-y-4 space-y-2 md:w-[30%]">
                                                              <button
                                                                style={{
                                                                  background:
                                                                    element && element.colorCode
                                                                      ? element.colorCode
                                                                      : "#DDDDDD",
                                                                }}
                                                                className={" px-4 py-2 2xl:text-sm text-xs font-medium flex text-white uppercase tracking-widest  rounded-full cursor-default	"
                                                                }>{EditDetailsFn(element, 'bucket', element.bucket)}
                                                              </button>
                                                              <h1 className="text-xl font-medium 2xl:text-2xl text-slate-900 md:px-4 ">
                                                                {((index1 + 1) > getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2 ? (index1 + 1 - getTopBottomIndex(getAssessmentReportByEmpIDData, "mid").range2).toString() : (index1 + 1).toString()) + ". "}{EditDetailsFn(element, 'dimension', element.dimension)}
                                                              </h1>
                                                            </div>

                                                            <div className="md:w-[70%] flex justify-between space-x-6 md:items-center items-end ">
                                                              <p className="flex-1 text-sm font-normal md:text-base text-slate-600 ">
                                                                {EditDetailsFn(element, 'desc' + (index1 + 1), element.details.desc)}
                                                              </p>
                                                              <div className="w-16 xl:w-28 md:w-20">
                                                                <img src={"/img/assessment/circleicons/" + (checkForGeneralship(element.bucket, element.dimension, isSchool)) + ((element.dimension.replace(' ', '_')).toLowerCase()) + ".svg"} />
                                                                {/* <img src={"/img/assessment/circleicons/" + element.dimension.toLowerCase() + ".svg"} /> */}
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </>
                                                      ) : null}
                                                    </>
                                                    : null))
                                                : null}
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </>

                                  )}


                              </> : null}


                            {showMainMenu === "Dashboard" ?
                              <>


                                <div className="items-center justify-between border-b md:flex">
                                  <div class="flex space-x-2 overflow-hidden overflow-x-auto text-xs font-medium text-gray-600 whitespace-nowrap tab-scroll">
                                    <span onClick={() => handleDashboard("Personality")} className={showDashboard === "Personality" ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4] to-[#2c67f2] text-white rounded-t-md border border-[#2196f3]" : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border"}>Personality Insights</span>
                                    <span onClick={() => handleDashboard("InterestInsights")} className={showDashboard === "InterestInsights" ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4] to-[#2c67f2] text-white rounded-t-md border border-[#2196f3]" : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border"}>Interest Insights</span>
                                    <span onClick={() => handleDashboard("WorkValue")} className={showDashboard === "WorkValue" ? "cursor-pointer text-sm py-2 px-4 font-medium bg-gradient-to-l from-[#62cff4] to-[#2c67f2] text-white rounded-t-md border border-[#2196f3]" : "cursor-pointer text-sm py-2 px-4 font-medium bg-[#fff] text-[#3D405B]/70 rounded-t-md border"}>Work Value Insights</span>
                                  </div>
                                </div>


                                {showDashboard === "Personality" ?
                                  <>
                                    <div className="grid grid-cols-1 gap-4 mt-6 md:grid-cols-2">
                                      {personalityData.map((item, index) => (
                                        <div className="p-6 space-y-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
                                          <div className="flex items-center">
                                            <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">{item.name}</h1>
                                          </div>
                                          <div>
                                            <div className="flex items-center justify-center">
                                              <SpeedMeter />
                                            </div>
                                            <div className="w-full px-3">
                                              <div className='space-y-4'>
                                                <div className='flex items-center flex-shrink-0 space-x-2 text-[#212121]'>
                                                  <span className='text-sm text-right w-60'>Imaginative and Ex</span>
                                                  <span>-</span>
                                                  <div className='w-full bg-[#f5f5f5] h-8 flex flex-row justify-start'>
                                                    <div className='flex items-center justify-start h-8' style={{ width: "45%", backgroundColor: "#64B5F6" }}>
                                                    </div>
                                                    <span className='flex items-center text-sm text-center p-2 text-[#212121]'>45%</span>
                                                  </div>
                                                </div>
                                                <div className='flex items-center flex-shrink-0 space-x-2 text-[#212121]'>
                                                  <span className='text-sm text-right w-60'>Practical and Real</span>
                                                  <span>-</span>
                                                  <div className='w-full bg-[#f5f5f5] h-8 flex flex-row justify-start'>
                                                    <div className='flex items-center justify-start h-8' style={{ width: "35%", backgroundColor: "#BA68C8" }}>
                                                    </div>
                                                    <span className='flex items-center text-sm text-center p-2 text-[#212121]'>35%</span>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </> : null}
                                {showDashboard === "InterestInsights" ?
                                  <>
                                    <div className="p-6 mt-6 space-y-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
                                      <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">Work Value Profile</h1>
                                      {personalityData.map((item, index) => (
                                        <div className="w-full px-3">
                                          <div className='space-y-4'>
                                            <div className='flex items-center flex-shrink-0 space-x-2 text-[#212121]'>
                                              <span className='text-sm text-right w-60'>Imaginative and Ex</span>
                                              <span>-</span>
                                              <div className='w-full bg-[#f5f5f5] h-8 flex flex-row justify-start'>
                                                <div className='flex items-center justify-start h-8' style={{ width: "45%", backgroundColor: "#64B5F6" }}>
                                                </div>
                                                <span className='flex items-center text-sm text-center p-2 text-[#212121]'>45%</span>
                                              </div>
                                            </div>
                                            <div className='flex items-center flex-shrink-0 space-x-2 text-[#212121]'>
                                              <span className='text-sm text-right w-60'>Practical and Real</span>
                                              <span>-</span>
                                              <div className='w-full bg-[#f5f5f5] h-8 flex flex-row justify-start'>
                                                <div className='flex items-center justify-start h-8' style={{ width: "35%", backgroundColor: "#BA68C8" }}>
                                                </div>
                                                <span className='flex items-center text-sm text-center p-2 text-[#212121]'>35%</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </> : null}
                                {showDashboard === "WorkValue" ?
                                  <>
                                    <div className="p-6 mt-6 space-y-6 transition duration-150 bg-white border rounded-lg hover:shadow-lg">
                                      <h1 className="font-medium xl:text-xl text-lg text-[#3D405B] whitespace-nowrap">Work Value Profile</h1>
                                      {personalityData.map((item, index) => (
                                        <div className="w-full px-3">
                                          <div className='space-y-4'>
                                            <div className='flex items-center flex-shrink-0 space-x-2 text-[#212121]'>
                                              <span className='text-sm text-right w-60'>Imaginative and Ex</span>
                                              <span>-</span>
                                              <div className='w-full bg-[#f5f5f5] h-8 flex flex-row justify-start'>
                                                <div className='flex items-center justify-start h-8' style={{ width: "45%", backgroundColor: "#64B5F6" }}>
                                                </div>
                                                <span className='flex items-center text-sm text-center p-2 text-[#212121]'>45%</span>
                                              </div>
                                            </div>
                                            <div className='flex items-center flex-shrink-0 space-x-2 text-[#212121]'>
                                              <span className='text-sm text-right w-60'>Practical and Real</span>
                                              <span>-</span>
                                              <div className='w-full bg-[#f5f5f5] h-8 flex flex-row justify-start'>
                                                <div className='flex items-center justify-start h-8' style={{ width: "35%", backgroundColor: "#BA68C8" }}>
                                                </div>
                                                <span className='flex items-center text-sm text-center p-2 text-[#212121]'>35%</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </> : null}



                              </> : null}

                          </section>

                        </div>
                      </> : null
                    }


                    <div className="w-full space-y-6 ">
                      {
                        backSummary ?
                          <>
                            <div className="space-y-6 lg:flex lg:space-x-6 lg:space-y-0">
                              <BackSummary
                                getAssessmentDimensionsData={getAssessmentDimensionsData}
                                EditButtonFn={EditButtonFn}
                                EditDetailsFn={EditDetailsFn}
                                loading={loading}
                                handleShowMainSummaryModalBack={handleShowMainSummaryModalBack}
                                contactExpertSection={contactExpertSection}
                                handleExpertContactModal={handleExpertContactModal}
                                topFiveTalent={topFiveTalent}
                                handleExpertContactModalBack={handleExpertContactModalBack}

                                getAssessmentReportByEmpIDData={getAssessmentReportByEmpIDData}
                                handleShowMainSummaryModal={handleShowMainSummaryModal}
                                crrDetail={crrDetail}
                                crrCodeUserDetails={crrCodeUserDetails}

                                handleInputExpertForm={handleInputExpertForm}
                                bookExpert={bookExpert}
                                userAssessmentExpertOrderPlaceData={userAssessmentExpertOrderPlaceData}
                                handleTestPayment={handleTestPayment}
                                isSchool={isSchool}
                              />
                            </div>
                          </> : null
                      }
                    </div>
                  </section>

                  <div className="pt-4 ">
                    <div className="flex justify-center w-full p-4 bg-white/10">
                      <p className="text-sm text-[#212121]/70 ">
                        © Powered by
                        <b className="ml-1 font-medium text-[#212121]/70">HappyPlus</b>
                      </p>
                    </div>
                  </div>
                </>
                :
                <>
                  <div className="items-center justify-between p-4 xl:flex lg:space-y-0">
                    <div className="flex items-center space-x-2">
                      <div className="w-full px-4 mx-auto xl:w-11/12 xl:px-0">
                        <div className="flex justify-between text-sm md:justify-start md:space-x-8 whitespace-nowrap lg:text-base">
                          <button onClick={() => handleMainMenu("Summary")} className={showMainMenu === "Summary" ? "flex md:flex-row flex-col gap-2 items-center   md:py-4 py-2 border-b-[3px] border-[#2196F3] text-[#212121" : "flex md:flex-row flex-col gap-2 items-center  md:py-4 py-2 border-b-[3px] border-transparent text-[#212121] opacity-90"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 40 40" fill="none">
                              <g clipPath="url(#clip0_4081_272)">
                                <path d="M35.556 6.66667H4.44488C3.85551 6.66667 3.29028 6.9008 2.87353 7.31755C2.45678 7.73429 2.22266 8.29952 2.22266 8.88889V31.1111C2.22266 31.7005 2.45678 32.2657 2.87353 32.6825C3.29028 33.0992 3.85551 33.3333 4.44488 33.3333H35.556C36.1454 33.3333 36.7106 33.0992 37.1273 32.6825C37.5441 32.2657 37.7782 31.7005 37.7782 31.1111V8.88889C37.7782 8.29952 37.5441 7.73429 37.1273 7.31755C36.7106 6.9008 36.1454 6.66667 35.556 6.66667ZM35.556 31.1111H4.44488V8.88889H35.556V31.1111Z" fill="#212121" />
                                <path d="M9.99978 15.5556H29.9998C30.2945 15.5556 30.5771 15.4385 30.7855 15.2301C30.9938 15.0217 31.1109 14.7391 31.1109 14.4444C31.1109 14.1498 30.9938 13.8671 30.7855 13.6588C30.5771 13.4504 30.2945 13.3333 29.9998 13.3333H9.99978C9.7051 13.3333 9.42248 13.4504 9.21411 13.6588C9.00574 13.8671 8.88867 14.1498 8.88867 14.4444C8.88867 14.7391 9.00574 15.0217 9.21411 15.2301C9.42248 15.4385 9.7051 15.5556 9.99978 15.5556Z" fill="#212121" />
                                <path d="M9.99978 20H29.9998C30.2945 20 30.5771 19.8829 30.7855 19.6746C30.9938 19.4662 31.1109 19.1836 31.1109 18.8889C31.1109 18.5942 30.9938 18.3116 30.7855 18.1032C30.5771 17.8948 30.2945 17.7778 29.9998 17.7778H9.99978C9.7051 17.7778 9.42248 17.8948 9.21411 18.1032C9.00574 18.3116 8.88867 18.5942 8.88867 18.8889C8.88867 19.1836 9.00574 19.4662 9.21411 19.6746C9.42248 19.8829 9.7051 20 9.99978 20Z" fill="#212121" />
                                <path d="M9.99978 24.4445H21.1109C21.4056 24.4445 21.6882 24.3274 21.8966 24.119C22.1049 23.9106 22.222 23.628 22.222 23.3333C22.222 23.0387 22.1049 22.756 21.8966 22.5477C21.6882 22.3393 21.4056 22.2222 21.1109 22.2222H9.99978C9.7051 22.2222 9.42248 22.3393 9.21411 22.5477C9.00574 22.756 8.88867 23.0387 8.88867 23.3333C8.88867 23.628 9.00574 23.9106 9.21411 24.119C9.42248 24.3274 9.7051 24.4445 9.99978 24.4445Z" fill="#212121" />
                              </g>
                              <defs>
                                <clipPath id="clip0_4081_272">
                                  <rect width="40" height="40" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                            <span>Summary</span>
                          </button>

                          <button onClick={() => handleMainMenu("Report")} className={showMainMenu === "Report" ? "flex md:flex-row flex-col gap-2 items-center   md:py-4 py-2 border-b-[3px] border-[#2196F3] text-[#212121" : "flex md:flex-row flex-col gap-2 items-center  md:py-4 py-2 border-b-[3px] border-transparent text-[#212121] opacity-90"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 40 40" fill="none">
                              <path d="M5 35V31.6667C5 29.8986 5.70238 28.2029 6.95262 26.9526C8.20286 25.7024 9.89856 25 11.6667 25H18.3333C20.1014 25 21.7971 25.7024 23.0474 26.9526C24.2976 28.2029 25 29.8986 25 31.6667V35M26.6667 5.21667C28.1007 5.58384 29.3717 6.41784 30.2794 7.58718C31.1871 8.75653 31.6797 10.1947 31.6797 11.675C31.6797 13.1553 31.1871 14.5935 30.2794 15.7628C29.3717 16.9322 28.1007 17.7662 26.6667 18.1333M35 35V31.6667C34.9915 30.1953 34.4965 28.768 33.5921 27.6073C32.6877 26.4467 31.4247 25.6178 30 25.25M8.33333 11.6667C8.33333 13.4348 9.03571 15.1305 10.286 16.3807C11.5362 17.631 13.2319 18.3333 15 18.3333C16.7681 18.3333 18.4638 17.631 19.714 16.3807C20.9643 15.1305 21.6667 13.4348 21.6667 11.6667C21.6667 9.89856 20.9643 8.20286 19.714 6.95262C18.4638 5.70238 16.7681 5 15 5C13.2319 5 11.5362 5.70238 10.286 6.95262C9.03571 8.20286 8.33333 9.89856 8.33333 11.6667Z" stroke="#212121" stroke-opacity="0.7" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                            <span>Report</span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-col justify-end gap-4 md:flex-row ">
                      <span onClick={() => closeReportummaryModal(false)} className="p-2 text-blue-500 duration-150 bg-blue-100 rounded-full cursor-pointer material-symbols-outlined hover:bg-blue-200 trasition" style={{ fontSize: "28px" }}>close</span>
                    </div>
                  </div>
                  <div className="pt-16 pb-24 flex items-center h-96 justify-center text-center text-xl text-[#3d405B]/70">Data Not Available</div>


                </>
              }
            </>


          </div>




        </div>
      </div>
    </>
  );
}
